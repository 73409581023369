import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Slide from "../utility/slide_content"
import MillionCard from "./OnemillionCard"

function SuggestedBytes(props) {
  var itemsToBeShown = 5
  var currentItem = 0

  return (
    <>
      <StaticQuery
        query={graphql`
          query EpisodesMainVideoSuggestMillion {
            SuperOps {
              episodes(
                where: { superpodPage: bytes }
                orderBy: episodeNumber_DESC
              ) {
                id
                audioDuration
                superpodPage
                episodeNumber
                image {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 540 } }
                    }
                  )
                }
                title
                guestName
                guestTitle
                superPodGuestTextTheme
                slug
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          // <div className="column-container">
          <div className="card-gap d-flex flex-wrap col-gap-24 mt48 justify-content-center">
            {data.SuperOps.episodes.map(episode => {
              const {
                id,
                episodeNumber,
                audioDuration,
                image,
                title,
                guestName,
                guestTitle,
                superPodGuestTextTheme,
                slug,
              } = episode

              return (
                <>
                  <div
                    style={{
                      display: currentItem <= itemsToBeShown ? "block" : "none",
                    }}
                  >
                    <span className="dspnonesp">{currentItem++} </span>
                    <Slide>
                      <MillionCard data={episode} slug={props.slug} />
                    </Slide>
                  </div>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default SuggestedBytes
